<template>
  <div>
  </div>
</template>

<script>
import SystemConfig from "../../config/SystemConfig";
import users from "@/request/users";

export default {
  name: "wechatSso",
  data() {
    return {
      ask: new users(),
    };
  },
  created() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.getCode(true);
  },
  methods: {
    getUrlParam() {
      var url = location.search;
      this.winUrl = url;
      var theRequest = {};
      if (url.indexOf('?') !== -1) {
        var str = url.substr(1);
        var strs = str.split('&');
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1]);
        }
      }
      return theRequest;
    },

    getCode(flag) {
      const code = this.getUrlParam().code; //截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      let local = SystemConfig.weChatSso.LOCALLINK;
      let APPID = SystemConfig.weChatSso.APPID;
      console.log('local', window.location.origin, local);
      if (code == null) {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + APPID + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect&connect_redirect=1';
      } else {
        this.code = code;
        this.weChatLogin(code); //把code传给后台获取用户信息
        console.log('ssoLogin getCode : ' + code);
      }
    },

    weChatLogin(code) {
      this.ask.wechatSso({code: code}).then(res => {
        if (res.code === 'success') {
          this.resetSetItem("loginUser", JSON.stringify(res.msg));
          this.$store.commit("user/setIsLogin", true)
          this.$store.commit("user/setUserInfo", res.msg)
          // setTimeout(() => {
          this.$router.push({path: '/'})
          // }, 1000);
          return false
        }
        // 需要绑定
        else if (res.code === 'fail') {
          this.$router.push({
            name: 'login',
            query: {
              wechatCode: res.msg
            }
          })
        } else {
          this.$router.push({path: '/login'})
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
